<template>
<div>
	<Navbar :nav-light="true"/>

	<section class="custom-bg-half bg-light bg-animation-left crypto-home d-table w-100" id="top">
		<div class="container position-relative" style="z-index: 1;">
			<div class="row mt-5 justify-content-center">
				<div class="col-lg-10">
					<div class="title-heading text-center">
						<img src="images/pngegg.png" height="250"  class="mover" alt="">
						<h1 class="heading text-shadow-title mt-4 mb-3 text-black">{{ $t("main.title") }}</h1>
						<p class="mx-auto text-black">
							<b>{{ $t("main.info") }}</b>
						</p>
					</div>                                
				</div>
			</div>
		</div>
	</section>

	<section class="section features-absolute" style="padding-bottom:50px">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-12 col-lg-10">
					<!-- <div class="row mt-2">
						<b-col>
							<b-button class="text-blue" variant="link" @click="tableSize = 'small'" :class="{ activePeriod: tableSize == 'small' }">{{ $t("rating.small") }}</b-button>
							<b-button class="text-blue" variant="link" @click="tableSize = 'full'" :class="{ activePeriod: tableSize == 'full' }">{{ $t("rating.full") }}</b-button>
						</b-col>
						<b-col class="d-flex justify-content-end">
							<b-button class="text-blue" variant="link" @click="days = 7" :class="{ activePeriod: days == 7 }">7</b-button>
							<b-button class="text-blue" variant="link" @click="days = 30" :class="{ activePeriod: days == 30 }">30</b-button>
							<b-button class="text-blue" variant="link" @click="days = 90" :class="{ activePeriod: days == 90 }">90</b-button>
							<b-button class="text-blue" variant="link" @click="days = 180" :class="{ activePeriod: days == 180 }">180</b-button>
							<b-button class="text-blue" variant="link" @click="days = 365" :class="{ activePeriod: days == 365 }">365</b-button>
						</b-col>
					</div> -->
					<div class="contaner" style="padding-top: 10px">
						<b-tabs fill small active-nav-item-class="font-weight-bold text-primary">
							<b-tab active :title="'7 ' + $t('rating.days')" @click="days = 7" title-link-class="font-weight-bold text-dark">
								<div class="table-responsive bg-white shadow-md" v-if="!loading">
									<rankTable 
										:tableSize="tableSize" 
										:loadingTop="loadingTop" 
										:userPool="userPool" 
										:userWatcherId="userWatcherId" 
										:pools="pools"
										:selectedPoolsId="selectedPoolsId" 
										@selectPools="selectPools"
										@removeUserWatcher="removeUserWatcher"
									/>
								</div>
							</b-tab>
							<b-tab :title="'30 ' + $t('rating.days')" @click="days = 30" title-link-class="font-weight-bold text-dark">
								<div class="table-responsive bg-white shadow-md" v-if="!loading">
									<rankTable 
										:tableSize="tableSize" 
										:loadingTop="loadingTop" 
										:userPool="userPool" 
										:userWatcherId="userWatcherId" 
										:pools="pools" 
										:selectedPoolsId="selectedPoolsId" 
										@selectPools="selectPools"
									/>
								</div>
							</b-tab>
							<b-tab :title="'90 ' + $t('rating.days')" @click="days = 90" title-link-class="font-weight-bold text-dark">
								<div class="table-responsive bg-white shadow-md" v-if="!loading">
									<rankTable 
										:tableSize="tableSize" 
										:loadingTop="loadingTop" 
										:userPool="userPool" 
										:userWatcherId="userWatcherId" 
										:pools="pools" 
										:selectedPoolsId="selectedPoolsId" 
										@selectPools="selectPools"
									/>
								</div>
							</b-tab>
							<b-tab :title="'180 ' + $t('rating.days')" @click="days = 180" title-link-class="font-weight-bold text-dark">
								<div class="table-responsive bg-white shadow-md" v-if="!loading">
									<rankTable 
										:tableSize="tableSize" 
										:loadingTop="loadingTop" 
										:userPool="userPool" 
										:userWatcherId="userWatcherId" 
										:pools="pools" 
										:selectedPoolsId="selectedPoolsId" 
										@selectPools="selectPools"
									/>
								</div>
							</b-tab>
							<b-tab :title="'365 ' + $t('rating.days')" @click="days = 365" title-link-class="font-weight-bold text-dark">
								<div class="table-responsive bg-white shadow-md" v-if="!loading">
									<rankTable 
										:tableSize="tableSize" 
										:loadingTop="loadingTop" 
										:userPool="userPool" 
										:userWatcherId="userWatcherId" 
										:pools="pools" 
										:selectedPoolsId="selectedPoolsId" 
										@selectPools="selectPools"
									/>
								</div>
							</b-tab>
						</b-tabs>
						<span class="watcher-info">
							{{ $t("watcher.info") }}
						</span>
						<b-row >
							<b-col cols="12" md="6" lg="8">
								<b-form-input v-model="watcher" :placeholder="$t('watcher.add_placeholder')" class="watcher" @input="watcherError = false"></b-form-input>
								<p v-if="watcherError" class="error-watcher">{{ errorMessage }}</p>
							</b-col>
							<b-col cols="12" md="6" lg="4">
								<div class="d-flex">
								<b-button variant="primary" class="watcher" @click="addWatcher"><b>{{ $t("watcher.add_btn") }}</b></b-button>
								<b-button v-if="userWatcherId" variant="danger"  class="watcher ml-1" @click="removeUserWatcher"><b>{{ $t("watcher.remove_btn") }}</b></b-button>
								</div>
							</b-col>
						</b-row>
						<div class="telegram-info my-3">{{ $t("main.telegram_bot") }}
							<a target="_blank" href="https://t.me/PooolsTopBot">telegram bot</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container mt-3" id="chart" v-if="!loading && selectedPools.length > 0">
			<div class="row justify-content-center">
				<div class="col-12 col-lg-10">
					<incomeChart :pools="selectedPools" :updater="chartUpdater" :days="days"/>
				</div>
			</div>
		</div>
	</section>

	<section class="section" id="faq">
		<FAQ />
	</section>

	<section class="section" id="roadmap">
		<Roadmap />
	</section>

	<!-- <section class="section" id="about">
		<About />
	</section> -->

	<Footer />
	<!-- Back to top -->
	<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
			<arrow-up-icon class="icons"></arrow-up-icon>
	</a>
	<!-- Back to top -->
</div>
</template>

<script>
import {
    ArrowUpIcon, RefreshCcwIcon, HelpCircleIcon, XCircleIcon
} from 'vue-feather-icons';
import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
import { rankService } from "../service/RankService"
import { watcherService } from "../service/WathcerService"
import rankTable from "../components/rankTable.vue"
import incomeChart from "../components/incomeChart.vue"
import FAQ from "../components/faq.vue"
import About from "../components/about.vue"
import Roadmap from "../components/roadmap"
import { isMobile } from "../utils"
import { mapGetters, mapActions } from 'vuex'

export default {
	components: {
		Navbar,
		Footer,
		ArrowUpIcon,
		RefreshCcwIcon,
		HelpCircleIcon,
		XCircleIcon,
		rankTable,
		incomeChart,
		FAQ,
		About,
		Roadmap
	},
	data () {
		return {
			loading: true,
			unit: 'th',
			days: 7,
			pools: [],
			selectedRowId: null,
			loadingTop: false,
			selectedPoolsId: [],
			koef: 5,
			watcher: null,
			watcherError: false,
			errorMessage: "",
			userWatcherId: null,
			tableSize: 'small',
			mobile: false,
			chartUpdater: 0
		}
	},
	computed: {
		selectedPools () {
			return this.pools.filter(p => this.selectedPoolsId.includes(p.pool_id))
		},
		userPool() {
			let f = this.pools.filter(p => p.isUser)
			return f.length > 0 ? f[0] : null
		},
		maxRate () {
			if (this.userPool) return +this.userPool.avrRate
			else return this.pools ? Math.max.apply(null, this.pools.map(p => +p.avrRate)) : 0
		}
	},
	async mounted () {
		if (this.$route.params.hasOwnProperty('lang')) {
			this.setLanguage(this.$route.params.lang)
		}
		this.mobile = isMobile()
		this.loading = true
		this.userWatcherId = localStorage.getItem('watcher')
		await this.top(this.days, this.unit, this.userWatcherId)
		if (this.pools.length > 0 && !this.pools[0].isUser) this.selectedPoolsId.push(this.pools[0].pool_id)
		if (this.pools.length > 3 && !this.pools[2].isUser) this.selectedPoolsId.push(this.pools[2].pool_id)
		this.loading = false
	},
	watch: {
		async days (value) {
			await this.top(value, this.unit, this.userWatcherId)
		},
		tableSize (value) {
			this.mobile = value == 'small'
		}
	},
	methods: {
		...mapActions(['setRuLanguage', 'setEnLanguage', 'setChLanguage']),
		async top (days, unit, watcherId) {
			// this.selectedPoolsId = []
			this.loadingTop = true
			let response = await rankService.top(days, unit, watcherId)
			this.pools = response
			if (this.userPool) this.selectedPoolsId.push(this.userPool.pool_id)
			this.chartUpdater++
			this.loadingTop = false
		},
		topHashInPersent (pool) {
			return (+pool.avrRate / this.maxRate * 100).toFixed(2)
		},
		addWatcher () {
			this.watcherError = false
			if (this.watcher.length > 4 ) {
				watcherService.add(this.watcher).then(response => {
					this.watcherError = response.error
					if (!response.error) {
						localStorage.setItem('watcher', response.watcherId)
						this.loading = true
						this.userWatcherId = localStorage.getItem('watcher')
						this.top(this.days, this.unit, this.userWatcherId).then(this.loading = false)
						this.loading = false
						this.watcher = null
					} else {
						this.watcherError = true
						this.errorMessage = response.message
					}
				})
			}
			else {
				this.watcherError = true
				this.errorMessage = "Enter correct watcher link!"
			}
		},
		removeUserWatcher () {
			localStorage.removeItem('watcher')
			this.userWatcherId = null
			this.loading = true
			this.top(this.days, this.unit, this.userWatcherId).then(this.loading = false)
			this.loading = false
		},
		setLanguage (lang) {
      if (lang === 'ru') {
        this.setRuLanguage()
        this.$i18n.locale = 'ru'
        return
      }
      if (lang === 'en') {
        this.setEnLanguage()
        this.$i18n.locale = 'en'
      }
      if (lang === 'ch') {
        this.setChLanguage()
        this.$i18n.locale = 'ch'
      }
    },
		selectPools (sp) {
			this.selectedPoolsId = sp
		}
	}
}
</script>

<style scoped>
	.custom-bg-half {
			background-size: cover;
			-ms-flex-item-align: center;
			-ms-grid-row-align: center;
			align-self: center;
			position: relative;
			background-position: center center;
			padding-bottom: 100px;
			padding-top: 20px;
	}
	.activePeriod {
		color: green !important;
		text-decoration: underline !important;
	}
	.watcher {
		height: 30px;
		width: 100%;
		text-align: center;
		padding-top: 0px;
		padding-bottom: 0px;
		padding-left: 15px;
		padding-right: 15px;
		margin-bottom: 5px;
	}
	.error-watcher {
		color: red;
		font-size: 0.8rem;
		text-align: center;
	}
	.watcher-info {
		font-size: 0.8rem;
		font-style: italic;
	}
	.telegram-info {
		font-size: 1rem;
		font-weight: 800;
	}
	.text-grey {
		color: #5a5a5a;
	}
	.text-blue {
		color: #030891 !important;
	}
</style>

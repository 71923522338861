import { config } from '../config'
import { getRequestOptions, handleResponse } from '@/service/UtilsService'

export const difficultyService = {
  get
}

function get (period) {
  const url = new URL(config.api.difficulty.get)
  url.searchParams.set('p', period)
  return fetch(url, getRequestOptions()).then(handleResponse)
}
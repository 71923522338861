<template>
  <div>
    <!-- Footer Start -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-md-8 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <h5 class="text-light footer-head">{{ $t('footer.about') }}</h5>
            <div class="list-unstyled footer-list mt-3">{{ $t('footer.reason') }}</div>
            <ul>
              <li>
                {{ $t('footer.p1') }}
              </li>
              <li>
                 {{ $t('footer.p2') }}
              </li>
              <li>
                {{ $t('footer.p3') }}
              </li>
            </ul>
            <span>
              <div>{{ $t('footer.thanks1') }}</div>
              <div>
                {{ $t('footer.thanks2') }}
                <b-button v-if="!showDonateAddr" variant="none" @click="showDonateAddr = !showDonateAddr" class="m-0 p-0 blink">{{ $t('footer.donate') }}</b-button>
                <span v-if="showDonateAddr" class="text-muted">
                  {{ wallet }} 
                  <b-button variant="none" class="m-0 p-0 text-muted" @click="copyURL()">
                    <copy-icon size="14" class="custom-class ml-2"></copy-icon>
                  </b-button>
                </span>
              </div>
            </span>
          </div>

          <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">{{ $t('footer.connect') }}</h5>
            <ul class="list-unstyled footer-list mt-3">
              <li>
                <a target="_blank" href="mailto:info@poools.top" class="text-foot">
                <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.mail') }}
                </a>
              </li>
              <li>
                <a target="_blank" href="https://t.me/PooolsTopBot" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i>
                  {{ $t('footer.bot') }}
                </a>
              </li>
              <li>
                <a target="_blank" :href="$t('footer.addr_group')" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i>
                  {{ $t('footer.group') }}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="text-sm-left mt-3">
          <p class="mb-0 copyright-text">
            © {{ new Date().getFullYear() }} Poools.top
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { CopyIcon } from 'vue-feather-icons'

export default {
  components: {
    CopyIcon
  },
  data() {
    return {
      showDonateAddr: false,
      wallet: "3PKfr7qxD8Y24Dm2VCNKPpfqMFX5aBwjg3"
    }
  },
  methods: {
    async copyURL() {
      try {
        await navigator.clipboard.writeText(this.wallet);
        alert('Wallet address is copied');
      } catch($e) {
        alert('Cannot copy');
      }
    }
  }
}
</script>

<style scoped>
  .custom-btn-footer {
    cursor: pointer;
  }
  .copyright-text {
    color: gainsboro;
  }
  .donate-btn {
    color: red;
  }
  @-webkit-keyframes pulsate {
    50% { color: #fff; text-shadow: 0 -1px rgba(0,0,0,.3), 0 0 5px #ffd, 0 0 8px #fff; }
  }
  @keyframes pulsate {
    50% { color: #fff; text-shadow: 0 -1px rgba(0,0,0,.3), 0 0 5px #ffd, 0 0 8px #fff; }
  }
  .blink {
    color: rgb(245,245,245);
    text-shadow: 0 -1px rgba(0,0,0,.1);
    -webkit-animation: pulsate 1.2s linear infinite;
    animation: pulsate 1.2s linear infinite;
  }
</style>

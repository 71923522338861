export const domain = process.env.NODE_ENV === 'production' ? 'https://poools.top/api/' : 'http://localhost:3334/api/'

export const config = {
  updateTimeSec: 60 * 5,
  api: {
    rank: {
      top: domain + 'rank/top',
      update: domain + 'rank/update',
      incomeHistory: domain + 'rank/incomehistory',
      totalPools: domain + 'rank/total'
    },
    watcher: {
      add: domain + 'watcher/add'
    },
    difficulty: {
      get: domain + 'difficulty'
    }
  }
}

export const chartColor = [
  '#32a852', '#3298a8', '#3a32a8', '#a2a832', '#a83263', '#03abff', '#ff03f2', '#ff039a', '#ff0329', '#ffb803', '##876610', '#d7b5e6'
]

import { config } from '../config'
import { getRequestOptions, handleResponse } from '@/service/UtilsService'

export const rankService = {
  top,
  update,
  incomeHistory,
  totalPools
}

function top (period, unit, userWatcherId) {
  const url = new URL(config.api.rank.top)
  url.searchParams.set('p', period)
  url.searchParams.set('u', unit)
  if (userWatcherId) url.searchParams.set('w', userWatcherId)
  return fetch(url, getRequestOptions()).then(handleResponse)
}

function update () {
  const url = new URL(config.api.rank.update)
  return fetch(url, getRequestOptions()).then(handleResponse)
}

function incomeHistory (poolId, period) {
  const url = new URL(config.api.rank.incomeHistory)
  url.searchParams.set('id', poolId)
  url.searchParams.set('p', period)
  return fetch(url, getRequestOptions()).then(handleResponse)
}

function totalPools () {
  const url = new URL(config.api.rank.totalPools)
  return fetch(url, getRequestOptions()).then(handleResponse)
}

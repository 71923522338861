import { config } from '../config'
import { getRequestOptions, postRequestOptions, handleResponse } from '@/service/UtilsService'

export const watcherService = {
  add
}

function add (link) {
  const url = new URL(config.api.watcher.add)
  return fetch(url, postRequestOptions({ link })).then(handleResponse)
}